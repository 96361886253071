<template>
  <div v-if="article" class="text-shadow Comment">
    <transition-group name="list" tag="div" style="margin-bottom:30px">
      <div v-for="c in commentList" v-if="article&&article.user" :key="c.guid" class="list-item" :class="{user:c.user&&(c.user.userName==article.user.userName)}">
        <div class="commentItem" :class="{user:c.user&&(c.user.userName==article.user.userName)}" @click="$emit('showWrite')" style="pointer-events:auto">
          <div style="flex:none;display:flex;align-items:center;margin-right:5px">
            <Avatar :user="c.user" :size="25"></Avatar>
          </div>
          <div style="flex:none;max-width: 50vw;">
            <div v-if="c.user" class="text-truncate writer" :class="{user:c.user&&(c.user.userName==article.user.userName)}">{{c.user.nickName}}<!--<span v-if="c.user.userName==article.user.userName">(作者)</span>-->：</div>
            <div v-else class="text-truncate writer">游客：</div>
            <div v-if="c.type=='点赞'" class="text-truncate" style="font-size:10px;line-height:1.2;">点赞 <i class="fas fa-thumbs-up"></i></div>
            <div v-else class="text-truncate" style="font-size:10px;line-height:1.2;">{{c.text}}</div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
  import Avatar from '../../Avatar'

  export default {
    components: {
      Avatar
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        comment: null,
        commentList: [],
        commentIndex: 0,
        commentListTimer: null,
        comments: [],
        commentsQuery: {
          page: 1
        },
        commentsPage: {},
      }
    },
    inject: {
      publicData: {
        default: {}
      },
    },
    computed: {
      articleGuid() {
        return this.publicData.guid || ''
      },
      article() {
        if (this.config.articleType) {
          switch (this.config.articleType) {
            case 'Pano':
              return this.pano
            case 'Tour':
              return this.tour
            default:
          }
        } else {
          return this.pano
        }
        return this.pano || {}
      },
      tour() {
        return this.publicData.tour || {}
      },
      pano() {
        return this.publicData.pano || {}
      },
    },
    watch: {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          InsertComment: this.InsertComment,
        },
        events: {
          showWrite: {
            target: 'writeComment', name: 'showWrite'
          }
        }
      })
      this.getComments().then(() => {
        this.startUpdateCommentList()
      })
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      InsertComment(res, reply) {
        if (reply) {
          var com = this.getRootComment(reply)
          com.replys.splice(0, 0, res.data)
        } else {
          this.comments.splice(this.commentIndex, 0, res.data)
        }
        this.sending = false
      },
      startUpdateCommentList() {
        if (this.commentListTimer) {
          return
        }
        this.commentListTimer = setInterval(this.updateCommentList, 1000)
      },
      stopUpdateCommentList() {
        clearInterval(this.commentListTimer)
        this.commentListTimer = null
      },
      updateCommentList() {
        if (!this.comments[this.commentIndex]) {
          return
        }
        var has = false
        for (var i in this.commentList) {
          if (this.commentList[i] == this.comments[this.commentIndex]) {
            has = true
            break
          }
        }
        if (!has) {
          this.commentList.splice(this.commentList.length, 0, this.comments[this.commentIndex])
          if (this.commentList.length > 3) {
            this.commentList.splice(0, this.commentList.length - 3)
          }
        }
        //this.comment = this.comments[this.commentIndex]
        this.commentIndex++
        if (this.comments.length - this.commentIndex < 10) {
          this.commentsAppendload()
        }
        if (this.commentIndex >= this.comments.length) {
          this.commentIndex = 0
        }
      },
      getRootComment(reply) {
        for (var i in this.comments) {
          if (reply.rootGUID == this.comments[i].guid) {
            return this.comments[i]
          }
        }
      },
      commentsAppendload() {
        if (this.loading) {
          return
        }
        if (this.lastPage) {
          return
        }
        this.loading = true
        this.commentsQuery.page++
        const config = {
          params: {
            ...this.commentsQuery,
            articleGUID: this.articleGuid,
          }
        }
        this.$axios
          .get(`/Api/ES/Comments`, config)
          .then((res) => {
            this.comments = [...this.comments, ...res.data.data]
            this.commentsPage = { ...res.data }
            delete this.commentsPage.data
            if (res.data.pageIndex >= res.data.totalPages) {
              this.lastPage = true
            }
            this.loading = false
            setTimeout(this.onscroll, 10)
          })
          .catch((err) => {
            this.loading = false
          })
      },
      getComments() {
        if (process.client) {
          if (!this.articleGuid) {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve(this.getComments())
              })
            })
          }
          return this.$axios.get(`/Api/ES/Comments`, {
            params: {
              ...this.commentsQuery,
              articleGUID: this.articleGuid,
            }
          }).then((res) => {
            this.comments = res.data.data
            this.commentsPage = res.data
            delete this.commentsPage.data
          })
        }
      },

    },
  }
</script>
<style scoped>
  .Comment {
    height: 0;
    display: flex;
    align-items: flex-end;
    position: relative;
    pointer-events: none;
  }

  .writer {
    font-size: 8px;
    line-height: 1.2;
    color: #999;
    font-weight: bold;
    margin-bottom: 4px;
    text-shadow: none;
  }

    .writer.user {
      color: #eee;
    }

  .writeCommentInfo {
    font-size: 10px;
    color: #666;
    padding: 10px;
    background-color: #fffc;
    box-shadow: #0008 0 0 10px;
  }

  .commentItem {
    display: inline-flex;
    background-color: #0005;
    border-radius: 50px;
    padding: 5px 15px 5px 5px;
    justify-content: left;
    margin-bottom: 10px;
  }

    .commentItem.user {
      background-color: #a005;
    }

  .list-item {
    /*    display: inline-block;
        margin-right: 10px;
    */
  }

  .list-enter-active,
  .list-leave-active,
  .list-move {
    transition: all 1s;
  }

  .list-enter {
    opacity: 0;
    transform: translateY(50px);
  }

  .list-leave-to {
    opacity: 0;
    /*    transform: translateY(-30px);
  */
  }
</style>
